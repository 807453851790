:root {
    --text-primary: #000;
    --bgPrimary: #fff;
    --bgSecondary: #f9f9f9;
    --blue: #0071bd;
    --light-blue: #e6f7ff;
    --dark-blue: #001529;
    --border: #d9d9d9;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a:hover {
    color: var(--blue) !important;
}

.app {
    display: flex;
    min-height: 100vh;
}

.navbar {
    flex: 0.2;
    background-color: var(--dark-blue);
}

.main {
    display: flex;
    flex-direction: column;
    flex: 0.8;
    width: 100%;
}
.routes {
    padding: 20px;
}
.nav-container {
    position: sticky;
    top: 0;
}

.logo-container {
    background-color: var(--dark-blue);
    display: flex;
    padding: 20px;
    align-items: center;
    width: 100%;
}
.logo {
    margin-left: 15px;
    margin-bottom: 0 !important;
}
.logo .logo-link {
    color: #fff;
}

.logo .logo-link:hover,
.logo .logo-link:focus {
    color: #fff !important;
}

.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
    color: #fff !important;
}

.menu-control-container {
    display: none !important;
    font-size: 1.2rem !important;
    background-color: var(--bgSecondary) !important;
    border: none !important;
    padding: 0 12px !important;
    margin-left: auto;
}

.loader {
    height: 81vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coin-detail-container {
    margin: 30px;
}

.coin-heading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid var(--border);
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 10px;
}

.coin-heading-container .coin-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 900;
}

.coin-heading-container p {
    font-size: 1rem;
    opacity: 0.9;
    margin-bottom: 20px;
}

.stats-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.stats-container h2 {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 20px;
    color: var(--blue);
}

.coin-details-heading {
    font-weight: 700 !important;
    margin-top: 20px !important;
    color: var(--blue) !important;
}

.coin-stats {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
    font-size: 1rem;
    opacity: 0.9;
    padding: 20px;
}

.coin-stats-name {
    display: flex;
    gap: 10px;
    font-size: 1rem;
}

.stats {
    font-weight: 800;
}

.coin-value-statistics-heading p {
    font-size: 1rem;
    opacity: 0.9;
}

.coin-desc-link {
    display: flex;
    gap: 40px;
    margin-top: 40px;
    padding-top: 20px;
}

.coin-desc-link h2 {
    font-weight: 700;
    color: var(--blue);
}

.coin-desc-link p {
    font-size: 1rem;
    opacity: 0.9;
}

.coin-desc-link a {
    color: var(--blue);
}

.coin-desc-link h3 {
    font-weight: 700;
}

.coin-desc {
    flex: 0.5;
}

.coin-links {
    padding: 0px 20px;
    flex: 0.5;
}

.coin-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border);
    padding: 20px;
}

.link-name {
    text-transform: capitalize;
    font-size: 1rem;
}

.coin-link a {
    color: var(--blue);
    font-weight: 700;
    font-size: 1rem;
}

.coin-link:hover,
.coin-stats:hover {
    background-color: var(--bgSecondary);
}

.select-news {
    width: 180px;
}

.news-card {
    display: flex;
    min-height: 300px !important;
}

.ant-card-body {
    display: flex;
    flex-direction: column;
}

.news-image-container {
    display: flex !important;
    justify-content: space-between !important;
}
.news-title {
    width: 70%;
}

.news-image-container .img {
    width: 100px;
    height: 100px;
}
.news-card p {
    color: black;
    margin: 10px 0;
}
.provider-meta {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.provider-name {
    margin-left: 10px;
}

.provider-container {
    display: flex;
    align-items: center;
}

.published-time {
    flex-shrink: 0;
}

.chart-header {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-top: 20px;
    color: #0071bd;
}
.chart-title {
    color: #0071bd !important;
}
.price-container {
    display: flex !important;
    gap: 20px !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}
.price-change {
    font-weight: 900 !important;
}
.current-price {
    margin-top: 0px !important;
    font-weight: 900 !important;
}
.home-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}
.show-more {
    margin-top: 0px !important;
}
.exchange-image {
    margin: 0px 10px !important;
}
.search-crypto {
    margin: 20px auto 30px auto;
    width: 250px;
}
.crypto-card-container {
    min-height: 65vh !important;
}

.crypto-card {
    flex-grow: 1 !important;
    min-width: 250px;
}

.crypto-card .crypto-image {
    height: 35px;
}
.select-timeperiod {
    width: 200px !important;
    margin-top: 20px !important;
}

.footer {
    background-color: var(--dark-blue);
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
}

@media screen and (max-width: 1300px) {
    .coin-detail-container {
        margin: 15px;
    }
}

@media screen and (max-width: 1170px) {
    .stats-container {
        flex-direction: column;
    }
    .coin-desc-link {
        flex-direction: column;
    }
    .stats-container h2 {
        margin-top: 0px;
    }
}

@media screen and (max-width: 991px) {
    .app {
        flex-direction: column;
        overflow: hidden;
    }

    .navbar {
        flex: 0;
    }

    .logo-container {
        padding: 15px;
    }

    .logo-container .logo {
        font-size: 24px;
    }

    .main {
        flex: 1;
        margin-top: 60px;
        margin-left: 0;
        margin-right: 10px;
    }

    .nav-container {
        position: fixed;
        width: 100%;
        z-index: 100;
        background-color: var(--bgSecondary);
    }

    .menu-control-container {
        display: block !important;
    }

    .ant-menu {
        position: absolute;
        right: 0;
    }
    .home-title {
        font-size: 1.2rem !important;
    }
    .show-more {
        font-size: 1.1rem !important;
    }
}

@media screen and (max-width: 500px) {
    .coin-links {
        padding: 0;
    }
    .coin-detail-container {
        margin: 0;
    }
    .heading {
        margin-top: 20px;
    }

    .heading:first-child {
        margin-top: 0;
    }
}
